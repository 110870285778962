import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: { requiresAuth: true },
    component: () => import('@/views/MainView'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/MainView/Home')
      },
      {
        path: '/device/:id?',
        name: 'Device',
        component: () => import('@/views/MainView/Home')
      },
      {
        path: '/export-data',
        name: 'ExportData',
        beforeEnter: checkAdminRights,
        component: () => import('@/views/MainView/ExportData')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/MainView/About')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/LoginView')
  },
  {
    path: '/implicit/callback',
    component: () => import('@/views/LoginView/LoginCallback')
  },
  {
    path: '/error',
    name: 'Error',
    // eslint-disable-next-line
    component: () => import('@/views/ErrorView')
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => {
      return next({ path: '/error', query: { message: 'Page not found' } })
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const onAuthRequired = async (from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = await Vue.prototype.$auth.isAuthenticated()
    if (!isAuthenticated) {
      return next({
        path: '/login',
        query: { redirect: from.fullPath }
      })
    }
  }
  return next()
}

async function checkAdminRights (to, from, next) {
  const { data: { data: isAdmin } } = await Vue.prototype.$api.get('/admin')
  if (!isAdmin) {
    return next({ path: '/error', query: { message: 'Page not found' } })
  }

  return next()
}

router.beforeEach(onAuthRequired)

export default router
